import moment from "moment";

export const formatDate = (date) => moment(date).format("D MMM YYYY");

export const getExpiredDate = (subscription, onlyCurrentPeriod = false) => {
  let result = undefined;

  if (subscription) {
    const currentPeriodEnd = subscription.current_period_end;
    const trialPeriodEnd = subscription.trial_end;

    const expiredDate = onlyCurrentPeriod
      ? currentPeriodEnd
      : Date.now() >= trialPeriodEnd
      ? currentPeriodEnd
      : trialPeriodEnd;

    result = formatDate(expiredDate * 1000);
  }

  return result || "";
};

export const getDiffInDays = (subscription) => {
  let diffInDays = 0;

  if (subscription) {
    const currentTime = Math.floor(moment().valueOf() / 1000);
    const expiredDate = subscription.trial_end || subscription.current_period_end;
    const diffInSeconds = Math.floor(expiredDate - currentTime);
    diffInDays = Math.floor(moment.duration(diffInSeconds, "s").asDays());
  }

  return diffInDays;
};
