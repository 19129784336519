import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Checkbox,
  Typography,
  SelectGroup,
  SelectGroupOption,
  Range,
  RadioGroup,
  RadioOption,
  RadioGroupValueType,
  Button,
  SvgIcon,
  clsx,
  globalStyles,
  useDebounce,
  useDidUpdate,
  Split,
  Tooltip,
  useBoolean,
  UseBooleanReturnType,
  TooltipTarget,
  Select,
} from "@gemlightbox/core-kit";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import JoyRadio from "@mui/joy/Radio";
import JoyRadioGroup from "@mui/joy/RadioGroup";

import { useStores } from "src/hooks";
import { cameraScalesArr } from "../camera-2d";
import { MEDIA_PAGE } from "src/constants";
import { useNavigate } from "react-router-dom";
import { deviceListModalStore } from "../device-list-modal";
import { cameraStore } from "../camera.store";
import { adjustments, focusConstraint, aspectRatiosOptions } from "../camera.constants";
import {
  filterKindMap,
  filterScenarioMap,
  vedioAngleMap,
  vedioSpeedMap,
} from "./camera-controls.constants";
import {
  SupportedAdjustments,
  CaptureModeType,
  CameraResolutionType,
  TurnTableSpeedControlKeys,
  TurnTableDegreesControlKeys,
  GemAIMapKeys,
  FilterMode,
  FilterScenarioMode,
} from "../camera.types";
import { MuiSwitch, MuiTooltip } from "src/external-ts/components";
import CallApp from "callapp-lib";
import { CallappOptions } from "callapp-lib/dist/type/types";
import { pushDataLayerEvent, pushMediaLocalSaveLayerEvent } from "src/utils";
import { appVersionComparisonPlus } from "src/utils/common.utils";

import { ReactComponent as ResetSVG } from "src/external-ts/assets/images/reset-grey.svg";
import { ReactComponent as ResetTutorialsSVG } from "src/external-ts/assets/images/camera/reset-tutorials.svg";
import { ReactComponent as ForHelpSVG } from "src/external-ts/assets/images/camera/for-help.svg";
import { ReactComponent as ContactSVG } from "src/external-ts/assets/images/camera/contact.svg";
import { ReactComponent as ProSVG } from "src/external-ts/assets/images/camera/pro.svg";
import { ReactComponent as AccordionExpandSVG } from "src/external-ts/assets/images/arrow-down-grey.svg";
import { ReactComponent as ShowMoreSVG } from "src/external-ts/assets/images/show-more-icon.svg";
import { ReactComponent as AutoPhotographyTipsSVG } from "src/external-ts/assets/images/camera/auto-photography-tips.svg";
import { ReactComponent as WinSVG } from "src/external-ts/assets/images/camera/win-icon.svg";
import { ReactComponent as EditSVG } from "src/external-ts/assets/images/edit-icon.svg";
import { ReactComponent as InvalidDirectorySVG } from "src/external-ts/assets/images/camera/invalid-directory-icon.svg";
import { ReactComponent as NoDeviceSVG } from "src/external-ts/assets/images/camera/no-device-icon.svg";
import { ReactComponent as SuccessSVG } from "src/external-ts/assets/images/camera/bluetooth-success-icon.svg";
import { ReactComponent as SwitchDeviceSVG } from "src/external-ts/assets/images/camera/switch-device-icon.svg";
import { ReactComponent as BluetoothSVG } from "src/external-ts/assets/images/camera/bluetooth.svg";
import { ReactComponent as LanguageSVG } from "src/external-ts/assets/images/language-icon.svg";
import styles from "./camera-controls.module.css";
import { LanguageType } from "src/models";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { ResolutionSelect } from "../resolution-select";
import { CameraKeyboardTips } from "../camera-keyboard-tips";

type CameraControlsProps = {
  captureMode: CaptureModeType;
  tutorialToggle: VoidFunction;
  bluetoothListBool: UseBooleanReturnType;
  bluetoothPermBool: UseBooleanReturnType;
  winBluetoothPermBool: UseBooleanReturnType;
  resDetectionBool: UseBooleanReturnType;
};

const focusConstraintFinal = focusConstraint;

export const CameraControls: React.FC<CameraControlsProps> = observer(
  ({ captureMode, tutorialToggle, bluetoothPermBool, resDetectionBool }) => {
    const debounce = useDebounce(100);
    const navigate = useNavigate();
    const { localeStore, userStore, tooltipStore, modalsStore } = useStores();

    const {
      name: cameraName,
      resolutionVideo,
      resolutionPhoto,
      focusMode,
      focusDistance,
      exposureTime,
      isBurstMode,
      isLogoWatermarkMode,
      videoSpeed,
      videoAngle,
      bluetoothDeviceSelected,
      isRecording,
      gemAIActive,
      connectionStatus,
      gemAIData,
      filterValue,
      openEclipse,
      filterScenarioValue,
      presetMode,
      generateAIDescription,
      dpi,
      isAutoSkuConfirmation,
      isFocusViewCorrection,
    } = cameraStore;
    let expTimeDef = window.$platform.getEclipseExposureTime(cameraName);

    const filterKindMapData = filterKindMap.map((item) => {
      const itemCopy = { ...item };
      const filtersOfbrightness = ["GemLightbox", "GemLightbox Pro"];
      const filtersOfexposureTime = ["Eclipse"];
      if (
        cameraName?.toLowerCase().includes("GemCam V2".toLowerCase()) ||
        cameraName?.toLowerCase().includes("GemCam V3".toLowerCase())
      ) {
        if (filtersOfbrightness.includes(itemCopy.label)) {
          itemCopy.default.u_levelsBrightness = 0.15;
        }
        if (filtersOfexposureTime.includes(itemCopy.label)) {
          itemCopy.default.u_exposureTime = expTimeDef;
        }
      } else {
        if (filtersOfbrightness.includes(itemCopy.label)) {
          itemCopy.default.u_levelsBrightness = 0.22;
        }
        if (filtersOfexposureTime.includes(itemCopy.label)) {
          itemCopy.default.u_exposureTime = expTimeDef;
        }
      }
      return itemCopy;
    });

    const isFocusAuto = focusMode === "continuous";
    const resolution = captureMode === "photo" ? resolutionPhoto : resolutionVideo;
    const aspectRatio = cameraStore.getAspectRatio(resolution);
    const [zoomIndex, setZoomIndex] = useState(0);
    const isOpenZoomTooltips = useBoolean(false);
    const isOpenForHelp = useBoolean(false);
    const isOpenKeyboardTips = useBoolean(false);
    const isShowmore = useBoolean(false);
    const filterScenarioExpanded = useBoolean(true);
    const aspectRatiosOptionsToUse = aspectRatiosOptions[captureMode].find(
      (f) => f.name === aspectRatio,
    )?.value;
    const currentResolutionLabel =
      aspectRatiosOptionsToUse?.find((item) => item.value === resolution)?.label || "";

    const handleGemAIChange = (key: GemAIMapKeys, value: boolean) => {
      cameraStore.setGemAIData(key, value);
    };

    const handleConnectClick = (openScan?: boolean) => {
      cameraStore.bluetoothConnectTurnTable().then((status) => {
        if (status < 5) {
          bluetoothPermBool.setTruthy();
        } else {
          modalsStore.open("DeviceListModal", {
            bluetoothPermBool,
            onClose: () => navigate(MEDIA_PAGE.path),
            openScan: openScan,
          });
        }
      });
    };

    const isWindows = () => {
      return !window.$platform.isApp && window.$platform.os === "Win";
    };

    const handleFilterChange = (value: FilterMode) => {
      if ((isWindows() && value === FilterMode.Eclipse) || filterValue === value) return;
      cameraStore.setFilterValue(value);
      cameraStore.handleInitCameraFiltersByLocalStorage(filterKindMapData[value].default);
      cameraStore.handleInitCameraSettingsByLocalStorage();
    };

    const handleFilterScenarioChange = (value: FilterScenarioMode) => {
      if (!cameraStore.isFilterSceneTutorialOpened) {
        modalsStore.open("FilterSceneTutorialModal", {
          onFinalOpened: () => {
            cameraStore.setIsFilterSceneTutorialOpened(true);
          },
        });
      }

      cameraStore.setFilterScenarioValue(value);

      const filters =
        value === FilterScenarioMode.Default
          ? filterKindMapData[filterValue].default
          : filterScenarioMap[value].default;
      cameraStore.handleInitCameraFiltersByLocalStorage(filters);
      cameraStore.setCameraSettingsLocalStorageByFilterValue();
    };

    const handleChangeResolution = (value: CameraResolutionType) => {
      if (!value) return;
      cameraStore.setResolutionMode("regular");
      cameraStore.changeResolution(value, captureMode);
      if (captureMode === "video" && window.$platform.isApp && window.$platform.os === "Win") {
        if (cameraStore.recommendedResolutions && cameraStore.recommendedResolutions.length > 0) {
          const recommendedResolutions = cameraStore.recommendedResolutions;
          const [width, height] = cameraStore.getResolutionByCameraResolutionValue(value);
          if (width * height > recommendedResolutions[0] * recommendedResolutions[1]) {
            resDetectionBool.setTruthy();
          }
        }
      }
    };

    const handleFocusModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const mode = event.target.checked ? "continuous" : "manual";
      cameraStore.setFocusMode(mode);
    };

    const handleFocusDistanceChange = (value: number) => {
      debounce.debounce(() => cameraStore.setFocusDistance(value));
    };

    const handleExposureTimeChange = (value: number) => {
      debounce.debounce(() => cameraStore.setCameraExposureTime(value));
      if (cameraStore.isKeyboardTipsOpened) return;
      if (!isOpenKeyboardTips.value) {
        isOpenKeyboardTips.setTruthy();
        cameraStore.setIsKeyboardTipsOpened(true);
      }
    };

    const handleChangeFilter = (uniform: SupportedAdjustments) => (value: number) => {
      cameraStore.changeFilter(uniform, value);
      if (cameraStore.isKeyboardTipsOpened) return;
      if (!isOpenKeyboardTips.value) {
        isOpenKeyboardTips.setTruthy();
        cameraStore.setIsKeyboardTipsOpened(true);
      }
    };

    const handleResetFilters = () => {
      cameraStore.resetCameraSetting().then(() => {
        cameraStore.setFocusMode("continuous");
        cameraStore.setResolutionMode("regular");
      });
      cameraStore.setBurstMode(true);
      cameraStore.setGemAIActive(true);
      cameraStore.setVideoSpeed(0);
      cameraStore.setVideoAngle(360);
      handleZoom(0, true);
    };

    const handleResetClick = () => {
      cameraStore.removeCameraSettingsLocalStorage(filterValue);
      handleResetFilters();
      cameraStore.resetGemAIData();
      cameraStore.setFilterScenarioValue(FilterScenarioMode.Default);
      cameraStore.setOpenEclipse(false);
      cameraStore.handleInitCameraFiltersByLocalStorage(filterKindMapData[filterValue].default);
      cameraStore?.mainImageComponent?.isAutoWhiteBalance(false);
      cameraStore.setCameraSettingsLocalStorageByFilterValue();
    };

    const handleZoom = (zoomIndex: number, isReset?: boolean) => {
      setZoomIndex(zoomIndex);
      const camera = cameraStore.camera;
      if (!camera) return;

      const zoomValue = cameraScalesArr[cameraScalesArr.length - 1 - zoomIndex][1];
      cameraStore.setMainImageComponentScale(zoomValue);

      if (!isReset) {
        handleFireZoomTooltips();
      }
    };

    const handleBurstModeValueChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      cameraStore.setBurstMode(isChecked);
      cameraStore.setGemAIActive(isChecked);
    };

    const handleEclipseModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!deviceListModalStore.DeviceSelected) return;
      const isChecked = event.target.checked;

      cameraStore.setOpenEclipse(isChecked);
      if (isChecked) {
        cameraStore.handleInitCameraFiltersByLocalStorage(
          filterKindMapData[FilterMode.Eclipse].default,
        );
      } else {
        handleFilterScenarioChange(cameraStore.filterScenarioValue);
      }
    };

    const handleLogoWatermarkModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      cameraStore.setLogoWatermarkMode(isChecked);
      if (isChecked && cameraStore.isOpendLogoWatermarkWindow) {
        handleLogoWatermarkSettingsClick();
      }
    };

    const handleLogoWatermarkSettingsClick = () => {
      modalsStore.open("LogoWatermarkModal", {
        originalSize: cameraStore.renderer.canvasSize,
        onSuccess: () => {
          if (cameraStore.isOpendLogoWatermarkWindow) {
            cameraStore.setLogoWatermarkMode(false);
          } else {
            cameraStore.rendererLogoWatermark();
          }
        },
      });
    };

    const handleLocalSaveModeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      if (isChecked) {
        if (!cameraStore.localSaveSettings?.folderPath) {
          handleLocalSaveSettingsClick(() => cameraStore.setIsLocalSaveMode(isChecked));
          return;
        }

        await cameraStore.getDriveInfo();
        if (!cameraStore.isLocalStorageSettingsAvailable()) {
          if (!cameraStore.driveInfo) {
            modalsStore.open("CustomNotificationModal", {
              icon: <SvgIcon icon={InvalidDirectorySVG} size={34} />,
              title: localeStore.t(
                'camera["local-save-settings-modal"].notification["error-location"]',
              ),
              message: localeStore.t(
                'camera["local-save-settings-modal"].notification["message-location"]',
              ),
              confirmAppearance: "primary",
              confirmText: localeStore.t(
                'camera["local-save-settings-modal"].notification["select-location"]',
              ),
              onOk: () =>
                handleLocalSaveSettingsClick(() => cameraStore.setIsLocalSaveMode(isChecked)),
            });
            pushMediaLocalSaveLayerEvent("enableError", {
              error_message: localeStore.t(
                'camera["local-save-settings-modal"].notification["error-location"]',
              ),
              base_path: cameraStore.localSaveSettings?.folderPath,
              reserved_disk_space: cameraStore.localSaveSettings?.miniStorageSpace,
              reserved_disk_space_unit: cameraStore.localSaveSettings?.storageSpaceUnit,
            });
          } else {
            modalsStore.open("CustomNotificationModal", {
              icon: <SvgIcon icon={InvalidDirectorySVG} size={34} />,
              title: localeStore.t(
                'camera["local-save-settings-modal"].notification["error-space"]',
              ),
              message: localeStore.t('camera["local-save-settings-modal"].notification.message'),
              confirmAppearance: "primary",
              confirmText: localeStore.t('camera["local-save-settings-modal"].notification.ok'),
              onOk: () =>
                handleLocalSaveSettingsClick(() => cameraStore.setIsLocalSaveMode(isChecked)),
            });
            pushMediaLocalSaveLayerEvent("enableError", {
              error_message: localeStore.t(
                'camera["local-save-settings-modal"].notification["error-space"]',
              ),
              base_path: cameraStore.localSaveSettings?.folderPath,
              reserved_disk_space: cameraStore.localSaveSettings?.miniStorageSpace,
              reserved_disk_space_unit: cameraStore.localSaveSettings?.storageSpaceUnit,
            });
          }
        } else {
          cameraStore.setIsLocalSaveMode(isChecked);
          pushMediaLocalSaveLayerEvent("enableSuccess", {
            base_path: cameraStore.localSaveSettings?.folderPath,
            reserved_disk_space: cameraStore.localSaveSettings?.miniStorageSpace,
            reserved_disk_space_unit: cameraStore.localSaveSettings?.storageSpaceUnit,
          });
        }
        return;
      }

      cameraStore.setIsLocalSaveMode(isChecked);
      pushMediaLocalSaveLayerEvent("disableSuccess", {
        base_path: cameraStore.localSaveSettings?.folderPath,
        reserved_disk_space: cameraStore.localSaveSettings?.miniStorageSpace,
        reserved_disk_space_unit: cameraStore.localSaveSettings?.storageSpaceUnit,
      });
    };

    const handleLocalSaveSettingsClick = (callback?: VoidFunction) => {
      modalsStore.open("LocalSaveSettingsModal", { onSuccess: callback });
    };

    const handlePresetModeValueChange = (value: RadioGroupValueType) => {
      cameraStore.setPresetMode(value as GemAIMapKeys);
    };

    const handleVideoSpeedValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      cameraStore.setVideoSpeed(Number(event.target.value) as TurnTableSpeedControlKeys);
    };

    const handleVideoAngleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      cameraStore.setVideoAngle(Number(event.target.value) as TurnTableDegreesControlKeys);
    };

    const handleForHelpItemClick = (value: string) => {
      switch (value) {
        case "contact": {
          window.open("https://picupmedia.com/contact-us/?contact-support", "_blank");
          break;
        }
        case "tutorial": {
          tutorialToggle();
          break;
        }
      }

      isOpenForHelp.setFalsy();
    };

    const handleFireZoomTooltips = () => {
      if (cameraStore.isZoomTipsOpened) return;
      if (!isOpenZoomTooltips.value) {
        isOpenZoomTooltips.setTruthy();
        cameraStore.setIsZoomTipsOpened(true);
      }
    };

    const handleDownloadClick = () => {
      const options = {
        scheme: {
          protocol: "ms-windows-store",
        },
      } as CallappOptions;
      const callLib = new CallApp(options);

      callLib.open({
        path: "pdp/?productid=9NPMPGT367QS",
        callback: () => {
          pushDataLayerEvent({ event: "click:download_gemhub_windows_app" });
        },
      });
    };

    let calibTiptimeoutId = 0;
    const handleMouseEnterCalib = (event: React.MouseEvent<HTMLButtonElement>) => {
      calibTiptimeoutId = setTimeout(() => {
        tooltipStore.open(
          <div className={styles.tooltipCalib}>
            {localeStore.t('camera["camera-controls"].buttons["calib-tip"]')}
          </div>,
          {
            target: event.target as TooltipTarget,
            appearance: "promo",
            position: "top",
            withAngle: true,
            className: styles.fadeEffect,
          },
        );
        setTimeout(() => {
          if (tooltipStore.tooltip) tooltipStore.tooltip.className = styles.fadeIn;
        }, 80);
      }, 600) as unknown as number;
    };

    const handleMouseLeaveCalib = () => {
      clearTimeout(calibTiptimeoutId);
      if (tooltipStore.tooltip?.className) tooltipStore.tooltip.className = styles.fadeEffect;
      tooltipStore.close();
    };

    useDidUpdate(() => {
      //update camera scale on zoom change
      const index = cameraScalesArr.findIndex(
        ([, value]) => value === cameraStore.mainImageComponentScale,
      );
      if (index === -1) return;
      setZoomIndex(cameraScalesArr.length - 1 - index);
    }, [cameraStore.mainImageComponentScale]);

    useDidUpdate(() => {
      if (connectionStatus === "connected") {
        cameraStore.handleInitCameraFiltersByLocalStorage(filterKindMapData[filterValue].default);
        if (openEclipse) {
          expTimeDef = window.$platform.getEclipseExposureTime(cameraName);
          cameraStore.getCameraExposureTime();
          cameraStore.setCameraExposureTime(expTimeDef);
        } else {
          cameraStore.resetCameraExposure();
        }
      }
    }, [connectionStatus]);

    useDidUpdate(() => {
      if (openEclipse) {
        expTimeDef = window.$platform.getEclipseExposureTime(cameraName);
        cameraStore.getCameraExposureTime();
        cameraStore.setCameraExposureTime(expTimeDef);
      } else {
        cameraStore.resetCameraExposure();
      }
    }, [openEclipse]);

    // Automatically preset after Bluetooth device is connected
    useDidUpdate(() => {
      if (!bluetoothDeviceSelected && filterValue !== FilterMode.GemCamOnly) {
        cameraStore.bluetoothConnectTurnTable().then((status) => {
          if (status < 5) {
            bluetoothPermBool.setTruthy();
          } else if (connectionStatus === "connected") {
            modalsStore.open("DeviceListModal", {
              bluetoothAutoConnect: true,
              bluetoothPermBool,
              onClose: () => navigate(MEDIA_PAGE.path),
            });
          }
        });
      }
    }, [bluetoothDeviceSelected && bluetoothDeviceSelected.name]);

    useDidUpdate(() => {
      if (deviceListModalStore.DeviceSelected) {
        if (!cameraStore.isGeneratedSkuTutorialOpened) {
          modalsStore.open("GeneratedSkuTutorialModal", {
            onFinalOpened: () => {
              cameraStore.setIsGeneratedSkuTutorialOpened(true);
            },
          });
        }

        if (deviceListModalStore.DeviceSelected.value === filterValue) return;
        handleFilterChange(deviceListModalStore.DeviceSelected.value);
      }
    }, [deviceListModalStore.DeviceSelected]);

    useDidUpdate(() => {
      cameraStore.rendererLogoWatermark();
    }, [isLogoWatermarkMode]);

    useDidUpdate(() => {
      cameraStore.setCameraSettingsLocalStorageByFilterValue();
    }, [
      cameraStore?.mainImageComponent?.state?.filters?.u_avgR,
      cameraStore?.mainImageComponent?.state?.filters?.u_avgG,
      cameraStore?.mainImageComponent?.state?.filters?.u_avgB,
    ]);

    return (
      <div className={clsx(styles.wrapper, "secondary-scrollbar")}>
        <SvgIcon
          id="forHelp"
          icon={ForHelpSVG}
          size={24}
          className={styles.forHelpBtn}
          onClick={isOpenForHelp.trigger}
        />

        <Tooltip
          className={styles.forHelpTips}
          target="forHelp"
          position="bottomRight"
          isOpen={isOpenForHelp.value}
          onClose={isOpenForHelp.setValue}
        >
          <div className={styles.forHelpList}>
            <div className={styles.forHelpItem} onClick={() => handleForHelpItemClick("contact")}>
              <SvgIcon icon={ContactSVG} size={20}></SvgIcon>
              <span>{localeStore.t('camera["camera-controls"]["help-tips"].contact')}</span>
            </div>
            <div className={styles.forHelpItem} onClick={() => handleForHelpItemClick("tutorial")}>
              <SvgIcon icon={ResetTutorialsSVG} size={20}></SvgIcon>
              <span>{localeStore.t('camera["camera-controls"]["help-tips"].tutorial')}</span>
            </div>
          </div>
        </Tooltip>

        <div className={clsx(styles.container, globalStyles.addScrollStyles)}>
          <Accordion
            className={styles.accordionWrapper}
            expanded={
              !!deviceListModalStore.DeviceSelected &&
              ((!!deviceListModalStore.DeviceSelected.device && captureMode === "video") ||
                (deviceListModalStore.DeviceSelected.selected &&
                  !deviceListModalStore.DeviceSelected.device))
            }
          >
            <AccordionSummary
              onClick={() => handleConnectClick()}
              expandIcon={
                deviceListModalStore.DeviceSelected ? (
                  <SvgIcon icon={SwitchDeviceSVG} size={[14, 12]} />
                ) : (
                  <SvgIcon icon={AccordionExpandSVG} size={26} />
                )
              }
              sx={[
                {
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(0deg)",
                  },
                  "&.MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "48px",
                  },
                  "& .MuiAccordionSummary-content.Mui-expanded": {
                    my: "12px",
                  },
                },
              ]}
            >
              <span className={styles.controlLabel}>
                {localeStore.t('camera["camera-controls"].device')}
              </span>
              <div className={styles.deviceSelected}>
                <div className={styles.deviceSelectedIcon}>
                  <SvgIcon
                    color={
                      bluetoothDeviceSelected ||
                      filterValue === FilterMode.GemCamOnly ||
                      deviceListModalStore?.DeviceSelected?.selected
                        ? "#0099A1"
                        : "#E3E4E6"
                    }
                    icon={
                      bluetoothDeviceSelected && deviceListModalStore.DeviceSelected
                        ? deviceListModalStore.DeviceSelected.src
                        : filterValue === FilterMode.GemCamOnly ||
                          deviceListModalStore?.DeviceSelected?.selected
                        ? deviceListModalStore?.DeviceSelected?.src
                        : NoDeviceSVG
                    }
                  />
                  {(bluetoothDeviceSelected || filterValue === FilterMode.GemCamOnly) && (
                    <SvgIcon className={styles.deviceSelectedIconSuccess} icon={SuccessSVG} />
                  )}
                </div>
                <span className={styles.deviceName}>
                  {deviceListModalStore.DeviceSelected?.label ??
                    localeStore.t('camera["camera-controls"].device')}
                </span>
              </div>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
              {!bluetoothDeviceSelected && (
                <div className={styles.bluetoothStatus}>
                  <SvgIcon icon={BluetoothSVG} size={24}></SvgIcon>
                  <span className={styles.bluetoothDesc}>
                    {localeStore.t('camera["camera-controls"].turntable.not')}
                  </span>
                  <Button className={styles.connectBtn} onClick={() => handleConnectClick(true)}>
                    {localeStore.t('camera["camera-controls"].turntable.connect')}
                  </Button>
                </div>
              )}
              {captureMode === "video" && !!bluetoothDeviceSelected && (
                <div className={styles.videoTurntableBox}>
                  <div className={styles.turntableSettingTitle}>
                    {localeStore.t('camera["camera-controls"].turntable.angle')}
                  </div>
                  <JoyRadioGroup
                    orientation="horizontal"
                    aria-labelledby="segmented-controls-example"
                    name="angle"
                    value={videoAngle}
                    onChange={handleVideoAngleValueChange}
                    sx={{
                      minHeight: 36,
                      padding: "4px",
                      borderRadius: "6px",
                      bgcolor: "var(--background-light-grey)",
                      "--RadioGroup-gap": "4px",
                      "--Radio-actionRadius": "4px",
                    }}
                  >
                    {vedioAngleMap.map((item) => (
                      <JoyRadio
                        key={item.value}
                        color="neutral"
                        value={item.value}
                        disableIcon
                        label={item.label}
                        variant="plain"
                        sx={{
                          px: 2.4,
                          alignItems: "center",
                        }}
                        slotProps={{
                          action: ({ checked }) => ({
                            sx: {
                              ...(checked && {
                                bgcolor: "background.surface",
                                boxShadow: "sm",
                                "&:hover": {
                                  bgcolor: "background.surface",
                                },
                              }),
                            },
                          }),
                        }}
                      />
                    ))}
                  </JoyRadioGroup>
                  <div className={clsx(styles.turntableSettingTitle, styles.mt12)}>
                    {localeStore.t('camera["camera-controls"].turntable.speed')}
                  </div>
                  <JoyRadioGroup
                    orientation="horizontal"
                    aria-labelledby="segmented-controls-example"
                    name="speed"
                    value={videoSpeed}
                    onChange={handleVideoSpeedValueChange}
                    sx={{
                      minHeight: 36,
                      padding: "4px",
                      borderRadius: "6px",
                      bgcolor: "var(--background-light-grey)",
                      "--RadioGroup-gap": "4px",
                      "--Radio-actionRadius": "4px",
                    }}
                  >
                    {vedioSpeedMap.map((item) => (
                      <JoyRadio
                        key={item.value}
                        color="neutral"
                        value={item.value}
                        disableIcon
                        label={localeStore.t(
                          `camera["camera-controls"].turntable.${item.lang as "fast" | "slow"}`,
                        )}
                        variant="plain"
                        sx={{
                          flex: 1,
                          alignItems: "center",
                          textAlign: "center",
                        }}
                        slotProps={{
                          action: ({ checked }) => ({
                            sx: {
                              ...(checked && {
                                bgcolor: "background.surface",
                                boxShadow: "sm",
                                "&:hover": {
                                  bgcolor: "background.surface",
                                },
                              }),
                            },
                          }),
                        }}
                      />
                    ))}
                  </JoyRadioGroup>
                </div>
              )}
            </AccordionDetails>
          </Accordion>
          <div className={styles.controlContainer} id="camera-controls-auto-focus">
            <div className={styles.controlSummary}>
              <span className={styles.controlLabel}>
                {localeStore.t('camera["camera-controls"]["auto-focus-title"]')}
              </span>
              <MuiSwitch checked={isFocusAuto} onChange={handleFocusModeChange}></MuiSwitch>
            </div>
            {!isFocusAuto && (
              <div className={styles.rangeContainer}>
                <Range
                  className={styles.range}
                  min={focusConstraintFinal.focusDistance.min}
                  max={focusConstraintFinal.focusDistance.max}
                  step={focusConstraintFinal.focusDistance.step}
                  value={focusDistance}
                  onChange={handleFocusDistanceChange}
                  disableError
                  enableExtraFocus={true}
                  forId="camera-controls-auto-focus"
                />
              </div>
            )}
          </div>
          <CameraKeyboardTips
            type="zoom"
            className={styles.forHelpTips}
            isOpen={isOpenZoomTooltips}
          >
            <div
              className={clsx(styles.controlContainer, styles.zoomContainer)}
              id="camera-controls-zoom"
            >
              <div className={styles.controlSummary}>
                <span className={styles.controlLabel}>
                  {localeStore.t('camera["camera-controls"]["zoom-title"]')}
                </span>
                <Typography size="small" color="textSecondary">
                  {zoomIndex + 1}x
                </Typography>
              </div>
              <div className={styles.rangeContainer}>
                <Range
                  className={styles.range}
                  min={0}
                  max={cameraScalesArr.length - 1}
                  step={1}
                  value={zoomIndex}
                  onChange={(value) => handleZoom(value, false)}
                  disableError
                  enableExtraFocus={true}
                  forId="camera-controls-zoom"
                />
              </div>
              <span className={styles.keyboardTips}>
                {localeStore.t('camera["camera-controls"]["zoom-tips"]')}
              </span>
            </div>
          </CameraKeyboardTips>
          <Accordion
            className={styles.accordionWrapper}
            defaultExpanded
            disabled={!gemAIActive || !userStore.isSubscribed}
          >
            <AccordionSummary
              expandIcon={<SvgIcon icon={AccordionExpandSVG} size={26} />}
              sx={[
                {
                  "&.MuiAccordionSummary-root.Mui-expanded": {
                    minHeight: "48px",
                  },
                  "& .MuiAccordionSummary-content.Mui-expanded": {
                    my: "12px",
                  },
                },
              ]}
            >
              <span className={styles.controlLabel}>
                GemAI <SvgIcon className={styles.titleBadge} icon={ProSVG} size={26}></SvgIcon>
              </span>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
              <div className={styles.selectAiLanguageWrapper}>
                <SvgIcon icon={LanguageSVG} size={16} />
                <Select
                  className={styles.selectAiLanguage}
                  inputWrapperClassName={styles.selectAiLanguageInput}
                  dropdownClassName={styles.selectAiLanguageDropdown}
                  data-name="ai-languag-dropdown"
                  data-hj-allow
                  appearance="primaryV2"
                  status="white"
                  disableError={true}
                  labelAlign="left"
                  label={localeStore.t('settings.profile["language-block"]["ai-language"]')}
                  selectedOptionsKeys={userStore.aiLanguage}
                  options={Object.values(LanguageType).map((item) => ({
                    label: localeStore.t(
                      `settings.profile["language-block"]["language-labels"]["${item.toLowerCase()}"]` as LocaleCodeTypes,
                    ),
                    value: item,
                  }))}
                  onChange={(e) => {
                    userStore.setAiLanguage(e?.value);
                  }}
                  disableClearing
                />
              </div>
              {!gemAIData.description[filterValue].hidden && (
                <div className={styles.gemAIOptions}>
                  <Checkbox
                    label={localeStore.t('camera["camera-controls"].ai.desc')}
                    checked={generateAIDescription!}
                    onChange={(value) => handleGemAIChange("description", value)}
                    disableError
                    disabled={!gemAIActive || !userStore.isSubscribed}
                  />
                </div>
              )}
              {!openEclipse && (
                <RadioGroup
                  className={styles.aiRadioGroup}
                  value={presetMode}
                  onChange={handlePresetModeValueChange}
                  disableError
                  disabled={!gemAIActive || !userStore.isSubscribed}
                >
                  {/* <RadioOption value="model">Model shots</RadioOption> */}
                  {!gemAIData.background[filterValue].hidden && (
                    <RadioOption value="background">
                      {localeStore.t('camera["camera-controls"].ai["remove-bg"]')}
                    </RadioOption>
                  )}
                  {!gemAIData.retouch[filterValue].hidden && (
                    <RadioOption value="retouch">
                      {localeStore.t('camera["camera-controls"].ai.retouching')}
                    </RadioOption>
                  )}
                </RadioGroup>
              )}
            </AccordionDetails>
          </Accordion>
          {captureMode === "video" && (
            <div className={styles.controlContainer}>
              <div className={styles.controlSummary}>
                <span className={styles.controlLabel}>
                  {localeStore.t('camera["camera-controls"]["auto-photography-title"]')}
                  <MuiTooltip
                    title={localeStore.t('camera["camera-controls"]["auto-photography-tips"]')}
                  >
                    <div>
                      <SvgIcon
                        icon={AutoPhotographyTipsSVG}
                        size={16}
                        className={styles.ml4}
                      ></SvgIcon>
                    </div>
                  </MuiTooltip>
                </span>
                <MuiSwitch checked={isBurstMode} onChange={handleBurstModeValueChange}></MuiSwitch>
              </div>
            </div>
          )}
          <div className={clsx(styles.controlContainer, styles.adjustmentsContainer)}>
            <div className={styles.px16}>
              <span className={styles.controlLabel}>
                {localeStore.t("common.webgl2.filters.title")}
              </span>
              {(filterValue === FilterMode.GemLightboxPro ||
                filterValue === FilterMode.GemLightbox) && (
                <div className={clsx(styles.controlSummary, styles.mt12)}>
                  <span className={styles.controlLabel}>
                    {localeStore.t('camera["camera-controls"]["eclipse-mode"]')}
                    <MuiTooltip
                      title={localeStore.t('camera["camera-controls"]["eclipse-mode-tip"]')}
                    >
                      <div>
                        <SvgIcon
                          icon={AutoPhotographyTipsSVG}
                          size={16}
                          className={styles.ml4}
                        ></SvgIcon>
                      </div>
                    </MuiTooltip>
                  </span>
                  <MuiSwitch checked={openEclipse} onChange={handleEclipseModeChange}></MuiSwitch>
                </div>
              )}

              {[FilterMode.GemLightboxPro, FilterMode.GemLightbox].includes(filterValue) &&
                !openEclipse && (
                  <>
                    <div className={clsx(styles.adjustmentsFilterHeader, styles.mt8)}>
                      <div className={styles.adjustmentsFilterLabel}>
                        {localeStore.t("common.webgl2.scenario")}
                      </div>
                      <div className={styles.adjustmentsFilterDropdown}>
                        <Button
                          appearance="secondaryGhost"
                          className={styles.adjustmentsFilterDropdownAnchorEl}
                          onClick={filterScenarioExpanded.trigger}
                        >
                          {filterScenarioMap[filterScenarioValue].label}
                          <span
                            style={{
                              transform: filterScenarioExpanded.value
                                ? "rotate(180deg)"
                                : "rotate(0)",
                              transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                            }}
                          >
                            <SvgIcon icon={AccordionExpandSVG} size={26} />
                          </span>
                        </Button>
                      </div>
                    </div>
                    <div
                      className={clsx(styles.adjustmentsFilterMenu, {
                        [styles.expanded]: filterScenarioExpanded.value,
                      })}
                    >
                      {filterScenarioMap.map((item) => (
                        <div
                          key={item.label}
                          className={clsx(styles.adjustmentsFilterItem)}
                          onClick={() => handleFilterScenarioChange(item.value)}
                        >
                          <div
                            className={clsx(styles.icon, {
                              [styles.active]: filterScenarioValue === item.value,
                            })}
                          >
                            <SvgIcon icon={item.src} size={item.size}></SvgIcon>
                          </div>
                          <div className={styles.label}>{item.label}</div>
                        </div>
                      ))}
                    </div>
                  </>
                )}
            </div>
            <div
              className={clsx(openEclipse ? styles.adjustmentsListH : styles.adjustmentsList, {
                [styles.expanded]: isShowmore.value,
              })}
            >
              {openEclipse && cameraStore?.exposureTimeRes && (
                <>
                  <CameraKeyboardTips
                    type="adjustments"
                    className={styles.forHelpTips}
                    placement="left-start"
                    isOpen={isOpenKeyboardTips}
                  >
                    <span id="forKeyboardTips" className={clsx(styles.keyboardTips, styles.px16)}>
                      {localeStore.t('camera["camera-controls"]["adjustments-tips"]')}
                    </span>
                  </CameraKeyboardTips>
                  <div className={clsx(styles.rangeContainer, styles.px16)}>
                    <Range
                      className={styles.range}
                      label={localeStore.t('camera["camera-controls"]["exposure-title"]')}
                      min={cameraStore?.exposureTimeRes?.exposureMinValue}
                      max={cameraStore?.exposureTimeRes?.exposureMaxValue}
                      step={1}
                      value={exposureTime}
                      onChange={handleExposureTimeChange}
                      showRangeValue
                      disableError
                      enableExtraFocus={true}
                    />
                  </div>
                </>
              )}
              {!openEclipse && (
                <>
                  <div className={clsx(styles.calibrateContainer, styles.px16)}>
                    <Button
                      className={styles.calibBtn}
                      onClick={() => cameraStore?.mainImageComponent?.isAutoWhiteBalance(true)}
                      onMouseEnter={handleMouseEnterCalib}
                      onMouseLeave={handleMouseLeaveCalib}
                      appearance="secondaryOutlined"
                    >
                      {localeStore.t('camera["camera-controls"].buttons.calibrate')}
                    </Button>
                    <Checkbox
                      label={localeStore.t('camera["camera-controls"].buttons["view-correct"]')}
                      checked={isFocusViewCorrection}
                      onChange={() => cameraStore.setIsFocusViewCorrection(!isFocusViewCorrection)}
                      disableError
                    />
                  </div>
                  <CameraKeyboardTips
                    type="adjustments"
                    className={styles.forHelpTips}
                    placement="left-start"
                    isOpen={isOpenKeyboardTips}
                  >
                    <span id="forKeyboardTips" className={clsx(styles.keyboardTips, styles.px16)}>
                      {localeStore.t('camera["camera-controls"]["adjustments-tips"]')}
                    </span>
                  </CameraKeyboardTips>
                </>
              )}
              {adjustments.map(({ name, uniform, min, max, step, hidden }) => {
                const filterLocale = name as Split<SupportedAdjustments, "_">[1];
                const eclipseInclude = ["saturation", "sharpness"];
                return (
                  <div
                    key={uniform}
                    className={clsx(styles.adjustmentItem, styles.px16, {
                      [styles.hide]:
                        hidden || (openEclipse && !eclipseInclude.includes(filterLocale)),
                    })}
                  >
                    <Range
                      className={styles.range}
                      rangeWrapperClassName={clsx({ [styles.hueRange]: name === "hue" })}
                      label={localeStore.t(`common.webgl2.filters.${filterLocale}`)}
                      min={min}
                      max={max}
                      step={step}
                      roundValue={2}
                      value={
                        cameraStore.mainImageComponent?.state?.filters[uniform] ??
                        filterKindMapData[filterValue].default[uniform] ??
                        0
                      }
                      onChange={handleChangeFilter(uniform)}
                      showRangeValue
                      disableError
                      disableProgress={name === "hue"}
                      enableExtraFocus={true}
                    />
                  </div>
                );
              })}
            </div>
            <div
              className={clsx(styles.showMore, {
                [styles.active]: isShowmore.value,
              })}
              onClick={isShowmore.trigger}
            >
              {isShowmore.value
                ? localeStore.t('camera["camera-controls"]["show-less"]')
                : localeStore.t('camera["camera-controls"]["show-more"]')}
              <SvgIcon className={styles.icon} icon={ShowMoreSVG} size={16} />
            </div>
          </div>
          <div className={styles.controlContainer}>
            <span className={styles.controlLabel}>
              {localeStore.t('camera["camera-controls"]["aspect-ratios"]')}
            </span>
            <ResolutionSelect
              selectedOption={aspectRatio}
              onChange={(ar) => {
                const resolutions = aspectRatiosOptions[captureMode]?.find(
                  (f) => f.name === ar,
                )?.value;
                if (resolutions) {
                  handleChangeResolution(resolutions[0].value as CameraResolutionType);
                }
              }}
            />
            <SelectGroup
              inputWrapperClassName={styles.resolutionSelectInputWrapper}
              inputClassName={clsx(styles.resolutionSelectInput, {
                [styles.active]: cameraStore.resolutionMode === "regular",
              })}
              appearance="primaryV2"
              label={localeStore.t('camera["camera-controls"]["resolution-label"]')}
              value={resolution}
              searchValue={currentResolutionLabel}
              onChange={handleChangeResolution}
              disableSearch
              disableClear
              disableError
              data-hj-allow
            >
              {aspectRatiosOptionsToUse?.map(({ label, value }) => {
                return (
                  <SelectGroupOption
                    key={`${value}`}
                    className={styles.marketPlaceOption}
                    value={value}
                  >
                    <div className={styles.marketPlaceOptionDiv}>
                      <span
                        className={clsx(styles.aspectRatioText, {
                          [styles.active]: value === resolution,
                        })}
                      >
                        {label}
                      </span>
                    </div>
                  </SelectGroupOption>
                );
              })}
            </SelectGroup>
            <Checkbox
              label={localeStore.t('camera["camera-controls"]["dpi-setting"]')}
              checked={dpi === 300}
              onChange={(checked) => cameraStore.setDpi(checked ? 300 : 0)}
              disableError
            />
          </div>
          <div className={styles.controlContainer}>
            <div className={styles.controlSummary}>
              <span className={styles.controlLabel}>
                {localeStore.t('camera["camera-controls"]["enable-logo"]')}
                <SvgIcon
                  icon={EditSVG}
                  size={20}
                  className={styles.editIcon}
                  onClick={handleLogoWatermarkSettingsClick}
                ></SvgIcon>
              </span>
              <MuiSwitch
                checked={isLogoWatermarkMode}
                onChange={handleLogoWatermarkModeChange}
              ></MuiSwitch>
            </div>
          </div>
          {appVersionComparisonPlus("1.0.11.0", "1.1.7") && (
            <div className={styles.controlContainer}>
              <div className={styles.controlSummary}>
                <span className={styles.controlLabel}>
                  {localeStore.t('camera["local-save-settings-modal"]["switch-title"]')}
                  <SvgIcon
                    icon={EditSVG}
                    size={20}
                    className={styles.editIcon}
                    onClick={() => handleLocalSaveSettingsClick()}
                  ></SvgIcon>
                </span>
                <MuiSwitch
                  checked={cameraStore.localSaveSettings.isLocalSaveMode}
                  onChange={handleLocalSaveModeChange}
                ></MuiSwitch>
              </div>
            </div>
          )}
          <div className={styles.controlContainer}>
            <div className={styles.controlSummary}>
              <span className={styles.controlLabel}>
                {localeStore.t('camera["camera-controls"]["auto-sku-confirmation"]')}
              </span>
              <MuiSwitch
                checked={isAutoSkuConfirmation}
                onChange={() => cameraStore.setIsAutoSkuConfirmation(!isAutoSkuConfirmation)}
              ></MuiSwitch>
            </div>
          </div>

          <Button
            className={styles.resetBtn}
            appearance="secondaryGhost"
            onClick={() => handleResetClick()}
          >
            <SvgIcon icon={ResetSVG} />
            {localeStore.t('camera["camera-controls"]["reset-default"]')}
          </Button>
          {window.$platform.type === "Web" && window.$platform.os === "Win" && (
            <Button
              className={styles.downWinApp}
              appearance="unset"
              onClick={() => handleDownloadClick()}
            >
              <SvgIcon icon={WinSVG} />
              {localeStore.t('camera["camera-controls"]["download-app"]')}
            </Button>
          )}
          <div className={clsx(styles.containerMask, { [styles.active]: isRecording })}></div>
        </div>
      </div>
    );
  },
);
