import { makeAutoObservable, runInAction } from "mobx";
import { isDeepEmpty, Nullable } from "@gemlightbox/core-kit";

import LocalStorage, { STORAGE_TYPES } from "src/common/helpers/local-storage.helper";
import {
  dashboardApiBuilder,
  getUserMe,
  getUserStorage,
  getUserTagManagerInfo,
  getGalleryInfo,
  getUserTagManagerInfoCallback,
  postLogin,
  putUpdateUser,
  deleteUser,
  GetUserTagManagerInfo,
  GetGalleryInfoResponseType,
  getBillingHistory,
  getUserProfile,
} from "src/api";
import { pushDataLayerEvent, validateEmail } from "src/utils";
import { LOGIN_PAGE } from "src/constants";
import { LanguageType, UserMeModel, UserProfileModel } from "src/models";
import { setTokenToStorage, loadMe, login, logout } from "src/redux/reducers/user";
import { store } from "src/redux/store";
import { InvoiceType, LoginDataType } from "./user.store.types";
import { showGemCamLocalKey } from "src/hooks";
import { ColorValue } from "src/external-ts/components";

//NOTE: Logic copy-pasted from redux
const userStorage = new LocalStorage(STORAGE_TYPES.USER_DATA);
const userLocalSettingsStorage = new LocalStorage(STORAGE_TYPES.USER_LOCAL_SETTINGS);

let initialData = userStorage.get();
initialData = isDeepEmpty(initialData) ? null : initialData;

const initialToken: string = typeof initialData?.token === "string" ? initialData.token : "";

export class UserStore {
  private _loadingLogin: boolean;
  public get loadingLogin() {
    return this._loadingLogin;
  }

  private _billingHistory: InvoiceType[];

  private _loadingMe = false;
  public get loadingMe() {
    return this._loadingMe;
  }

  private _loadingProfile = false;
  public get loadingProfile() {
    return this._loadingProfile;
  }

  public get billingHistory() {
    return this._billingHistory;
  }

  private _loadingMeSilently = false;
  public get loadingMeSilently() {
    return this._loadingMeSilently;
  }

  private _userMe: Nullable<UserMeModel> = initialData;
  public get userMe() {
    return this._userMe;
  }
  public get userUsage() {
    return this._userMe?.usage;
  }

  private _userProfile: Nullable<UserProfileModel>;
  public get userProfile() {
    return this._userProfile;
  }
  public get userImagesCount() {
    return this.userProfile?.user?.images_details.total_count || 0;
  }
  public get userVideosCount() {
    return this.userProfile?.user?.videos_details.total_count || 0;
  }

  public get userStorageUsage() {
    return this.userProfile?.user?.storage_usage;
  }

  public get isValid() {
    if (!this.userMe) return false;

    let isValid = true;

    const { firstName, lastName, email, phone } = this.userMe.user;

    if (!firstName?.trim()) {
      isValid = false;
    }

    if (!lastName?.trim()) {
      isValid = false;
    }

    if (!email?.trim() || !validateEmail(email)) {
      isValid = false;
    }

    if (!phone?.trim()) {
      isValid = false;
    }

    return isValid;
  }

  public get isSubscribed() {
    return this.userMe?.isSubscribed || false;
  }

  public get isSubaccount() {
    return this.userMe?.isSubaccount || false;
  }

  public get isShopifyUser() {
    return this.userMe?.isShopifyUser || false;
  }
  public get isRapnetUser() {
    return this.userMe?.isRapnetUser || false;
  }
  public get isPaypalUser() {
    return this.userMe?.user.isEnabledPayPalIntegration || false;
  }
  public get isStripeUser() {
    return this.userMe?.user.isEnabledStripeIntegration || false;
  }

  public get isFreeTierAvailable() {
    return this.userMe?.isFreeTierAvailable || false;
  }

  public get isCTFUser() {
    return this.userMe?.user?.isChoTaiFookUser || false;
  }

  public get isCTFSub() {
    return this.userMe?.user?.isChoTaiFookSub || false;
  }

  public get isCTF() {
    return this.isCTFUser || this.isCTFSub;
  }

  // TODO: this is a hack props for ios scrutiny, if the implementation of SSR is to be removed.
  public get isAppStoreDemoAccount() {
    // return this.userMe?.user?.email === "demo@picupmedia.com" || false;
    return this.userMe?.user?.isAppStoreDemoAccount || false;
  }

  private _storageUsage = 0;
  public get storageUsage() {
    return this._storageUsage;
  }

  private _token: string;
  public get token() {
    return this._token;
  }

  public set token(token: string) {
    this._token = token;
    dashboardApiBuilder.setTokenCallback(() => token);

    // TODO: remove once remove redux
    store.dispatch(setTokenToStorage(token));
  }

  public get isLogged() {
    return !!this._token;
  }

  private _userTagManagerInfo: Nullable<GetUserTagManagerInfo>;
  public get userTagManagerInfo() {
    return this._userTagManagerInfo;
  }

  private _loadingGalleryInfo = false;
  public get loadingGalleryInfo() {
    return this._loadingGalleryInfo;
  }

  private _galleryInfo: Nullable<GetGalleryInfoResponseType> = null;
  public get galleryInfo() {
    return this._galleryInfo;
  }

  public get hasAttemptedGemCamSetup() {
    const showGemCam = localStorage.getItem(showGemCamLocalKey) === "true";
    return showGemCam || this.userMe?.hasAttemptedGemCamSetup || false;
  }

  private _aiLanguage: string = "en";
  public get aiLanguage() {
    return this._aiLanguage;
  }

  public setAiLanguage(language?: LanguageType) {
    if (language) {
      runInAction(() => (this._aiLanguage = language));
      localStorage.setItem(STORAGE_TYPES.AI_LANGUAGE, language);
      userLocalSettingsStorage.set({ ...userLocalSettingsStorage.get(), aiLanguage: language });
    }
  }

  private _backgroundFill: ColorValue = "white";
  public get backgroundFill() {
    return this._backgroundFill;
  }

  public setBackgroundFill(color?: ColorValue) {
    if (color) {
      runInAction(() => (this._backgroundFill = color));
      userLocalSettingsStorage.set({ ...userLocalSettingsStorage.get(), backgroundFill: color });
    }
  }

  constructor() {
    this._resetStore();

    this._userMe = initialData && { ...initialData, _cache: true };
    this.token = initialToken;
    const storAiLanguage =
      localStorage.getItem(STORAGE_TYPES.AI_LANGUAGE) || userLocalSettingsStorage.get()?.aiLanguage;
    if (storAiLanguage && Object.values(LanguageType).includes(storAiLanguage as LanguageType)) {
      runInAction(() => (this._aiLanguage = storAiLanguage));
    }

    const backgroundFill = userLocalSettingsStorage.get()?.backgroundFill;
    if (backgroundFill) runInAction(() => (this._backgroundFill = backgroundFill));
    makeAutoObservable(this);
  }

  /* Requests ↓ */
  public async login({ shopifyToken = "", ...data }: LoginDataType) {
    runInAction(() => {
      this._loadingLogin = true;
    });

    const result = await postLogin
      .getRequest({
        data,
        headers: { shopify_token: shopifyToken },
      })
      .fetch();

    runInAction(() => {
      if (result.success) {
        userStorage.set({ ...userStorage.get(), ...result.success });

        this.token = result.success.token;

        // TODO: remove once remove redux
        store.dispatch(login(result.success));

        getUserTagManagerInfoCallback((response) => {
          pushDataLayerEvent({
            event: "gemhub:login",
            event_params: {
              user_id: response.user_id,
              account_type: response.account_type,
              last_touch_platform: response.last_touch_platform,
              space_used: response.space_used,
              current_paid_plan: response.current_paid_plan,
              space_free_max: response.space_free_max,
              is_trial: response.isTrial,
            },
            email: result.success.user.email,
          });
        });
      }

      this._loadingLogin = false;
    });

    return result;
  }

  public async loadUserMe() {
    runInAction(() => {
      this._loadingMe = true;
    });

    const result = await getUserMe.getRequest().fetch();

    runInAction(async () => {
      if (result.success) {
        userStorage.set({ ...userStorage.get(), ...result.success });

        // TODO: remove once remove redux
        store.dispatch(loadMe(result.success));

        this._userMe = result.success;

        await this.updateAttemptedGemCamSetup();
      }

      this._loadingMe = false;
    });

    return result;
  }

  public async loadUserProfile() {
    runInAction(() => {
      this._loadingProfile = true;
    });

    const result = await getUserProfile.getRequest().fetch();

    runInAction(() => {
      if (result.success) {
        this._userProfile = result.success;
      }

      this._loadingProfile = false;
    });

    return result;
  }

  public async loadUserMeSilently() {
    runInAction(() => {
      this._loadingMeSilently = true;
    });

    const result = await getUserMe.getRequest().fetch();

    runInAction(() => {
      if (result.success) {
        userStorage.set({ ...userStorage.get(), ...result.success });

        // TODO: remove once remove redux
        store.dispatch(loadMe(result.success));

        this._userMe = result.success;
      }

      this._loadingMeSilently = false;
    });

    return result;
  }

  public async loadGalleryInfo() {
    runInAction(() => {
      this._loadingGalleryInfo = true;
    });

    const result = await getGalleryInfo.getRequest().fetch();

    runInAction(() => {
      if (result.success) {
        this._galleryInfo = result.success;
      }

      this._loadingGalleryInfo = false;
    });

    return result;
  }

  public async checkGalleryInfo() {
    if (this.galleryInfo) return;
    return this.loadGalleryInfo();
  }

  public async loadUserTagManagerInfo() {
    const { success, details } = await getUserTagManagerInfo.getRequest().fetch();
    const { isCanceled } = details;

    if (isCanceled) return;

    runInAction(() => {
      if (success) this._userTagManagerInfo = success;
      else this._userTagManagerInfo = null;
    });
  }

  public async loadStorageUsage() {
    const { success } = await getUserStorage.getRequest().fetch();

    runInAction(() => {
      if (success) this._storageUsage = success;
    });
  }

  public async updateUser(data: FormData) {
    const updateResult = await putUpdateUser.getRequest({ data }).fetch();
    if (updateResult.success) await this.loadUserMeSilently();
    return updateResult;
  }

  public async deleteUser() {
    const deleteResult = await deleteUser.getRequest().fetch();
    if (deleteResult.details.isSuccess) this.logout();
    return deleteResult;
  }

  public async loadBillingHistory() {
    const { success } = await getBillingHistory.getRequest().fetch();

    if (success) {
      this._billingHistory = success;
    }
  }

  public async updateAttemptedGemCamSetup() {
    const showGemCam = localStorage.getItem(showGemCamLocalKey) === "true";
    if (showGemCam && !this.userMe?.hasAttemptedGemCamSetup) {
      const formData = new FormData();
      formData.append("hasAttemptedGemCamSetup", "true");
      await this.updateUser(formData);
    }
  }

  /* Requests ↑ */

  /* UI State ↓ */
  public logout(cancelRedirect = false) {
    pushDataLayerEvent({
      event: "gemhub:logout",
      event_params: {
        user_id: this._userMe?.user._id,
      },
    });
    store.dispatch(logout(false));
    userStorage.destroy();
    this._resetStore();
    !cancelRedirect && (document.location.href = LOGIN_PAGE.path + document.location.search);
  }

  /* UI State  ↑  */

  /* Helpers ↓ */
  private _resetStore() {
    this._loadingLogin = false;
    this._storageUsage = 0;
    this._userMe = null;
    this._userProfile = null;
    this._loadingMe = false;
    this._token = "";
    this._userTagManagerInfo = null;
    this._loadingGalleryInfo = false;
    this._galleryInfo = null;
    this._billingHistory = [];
  }

  /* Helpers ↑ */
}
