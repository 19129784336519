import React, { useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { FormikProps } from "formik";
import { observer } from "mobx-react-lite";
import {
  Form,
  FormField,
  isEmpty,
  Nullable,
  useApiRequestAbort,
  useAutocomplete,
  Vec2,
  Button,
  Typography,
  useDidUpdate,
  deepObserve,
  Webgl2New,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { MediaType, ProductModel, ProductTypeModel } from "src/models";
import { getProductNameIsUnique, getProducts, postProduct } from "src/api";
import { measureOptions, heightRequiredTypes, initialValues } from "../../ar-media.constants";
import { ArMediaFormErrorsType, ArMediaFormType, ArMediaType } from "../../ar-media.types";
import { canvasSize } from "../media-editor.constants";
import {
  GetARMainImageDataReturnType,
  pixelsToMeasurement,
  measurementToPixel,
} from "../media-editor.utils";

import styles from "./product-selector.module.css";

export type ProductSelectorProps = {
  product: Nullable<ProductModel>;
  productType: ProductTypeModel;
  initialMainImageRectData: GetARMainImageDataReturnType;
  mainImageComponent: Webgl2New.ImageRectComponent;
  renderer: Webgl2New.Webgl2dRenderer;
  onCancel: VoidFunction;
  onSubmit: (values: ArMediaFormType, product: Nullable<ProductModel>) => void;
};

export const ProductSelector: React.FC<ProductSelectorProps> = observer(
  ({
    product,
    productType,
    initialMainImageRectData,
    mainImageComponent,
    renderer,
    onCancel,
    onSubmit,
  }) => {
    const { localeStore } = useStores();

    const location = useLocation();
    const stateData = location.state as Nullable<ArMediaType>;

    const predefinedProductType =
      stateData?.media?.arData?.productType || stateData?.media?.productType;
    const isInEditAR = !!predefinedProductType;

    const formRef = useRef<FormikProps<ArMediaFormType> | null>(null);
    const newMeasureRef = useRef(initialValues.unit);
    const alreadyHasBlinkRef = useRef(
      product?.images.some(({ type }) => type === MediaType.blink) || false,
    );

    const [unique, setUnique] = useState(false);
    const [productLoading, setProductLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<Nullable<ProductModel>>(product);

    const isHeightRequiredType = heightRequiredTypes.includes(productType.name);

    const useUniqProductAbort = useApiRequestAbort();
    const useSelectAutoComplete = useAutocomplete(getProducts, "_id", "title", {
      searchKey: "title",
    });
    useSelectAutoComplete.onBeforeSearch(async (search) => {
      setProductLoading(true);
      setUnique(false);

      useUniqProductAbort.abort();

      const request = useUniqProductAbort.setRequest(
        getProductNameIsUnique.getRequest({ params: { title: search } }),
      );

      const { success, details } = await request.fetch();
      const { isCanceled } = details;

      if (isCanceled) return;

      if (typeof success === "boolean") {
        const isUnique = !success;
        setUnique(isUnique);
      }

      setProductLoading(false);
    });

    const formInitialValues: ArMediaFormType = useMemo(() => {
      return {
        ...initialValues,
        sku: product?._id || "",
        width: String(
          pixelsToMeasurement(initialMainImageRectData.width, initialValues.unit, productType.name),
        ),
        height: String(
          pixelsToMeasurement(
            initialMainImageRectData.height,
            initialValues.unit,
            productType.name,
          ),
        ),
      };
    }, []);

    useDidUpdate(
      () => {
        const callback = () => {
          const form = formRef.current;
          if (!form) return;

          const unit = newMeasureRef.current;

          const widthToSet = pixelsToMeasurement(
            mainImageComponent.transform.size.width,
            unit,
            productType.name,
          );
          const heightToSet = pixelsToMeasurement(
            mainImageComponent.transform.size.height,
            unit,
            productType.name,
          );

          form.setFieldValue("width", widthToSet);
          form.setFieldValue("height", heightToSet);
        };

        callback();

        return deepObserve(mainImageComponent, callback);
      },
      [mainImageComponent],
      true,
    );

    const handleProductSelect = (product: ProductModel) => {
      alreadyHasBlinkRef.current = product?.images?.some((media) => media.type === MediaType.blink);
      setSelectedProduct(product);
      formRef.current?.validateForm();
    };

    const handleCreateProduct = async (title: string) => {
      setProductLoading(true);

      const { success } = await postProduct.getRequest({ data: { title } }).fetch();
      if (!success) return;

      const product = success.rows.find((product) => product.title === title);
      setProductLoading(false);

      if (!product) return;

      alreadyHasBlinkRef.current = false;
      setSelectedProduct(product);
      useSelectAutoComplete.actions.setData((prevData) => {
        if (!prevData) return prevData;
        return { ...prevData, rows: prevData.rows.concat(product) };
      });
      useSelectAutoComplete.onSearch("");
      setUnique(false);
      formRef.current?.setFieldValue("sku", product._id);
      formRef.current?.validateForm();
    };

    const validateForm = (values: ArMediaFormType) => {
      const errors: ArMediaFormErrorsType = {};

      if (!isInEditAR && isEmpty(values.sku)) {
        errors.sku = localeStore.t(
          '["ar-media"]["media-editor"]["product-selector"]["error-message"].required',
        );
      }

      if (!isInEditAR && alreadyHasBlinkRef.current) {
        errors.sku = localeStore.t(
          '["ar-media"]["media-editor"]["product-selector"]["error-message"].link',
        );
      }

      // if (!isInEditAR && selectedProduct && selectedProduct?.images.length >= 10) {
      //   errors.sku = localeStore.t(
      //     '["ar-media"]["media-editor"]["product-selector"]["error-message"].images',
      //   );
      // }

      if (isHeightRequiredType && isEmpty(values.height)) {
        errors.height = localeStore.t(
          '["ar-media"]["media-editor"]["product-selector"]["error-message"].required',
        );
      }

      if (Number(values.height) <= 0) {
        errors.height = localeStore.t(
          '["ar-media"]["media-editor"]["product-selector"]["error-message"].height',
        );
      }

      if (isHeightRequiredType && isEmpty(values.unit)) {
        errors.unit = localeStore.t(
          '["ar-media"]["media-editor"]["product-selector"]["error-message"].required',
        );
      }

      return errors;
    };

    const handleSubmit = (values: ArMediaFormType) => onSubmit(values, selectedProduct);

    return (
      <Form
        className={styles.container}
        contentClassName={styles.formContent}
        initialValues={formInitialValues}
        validate={validateForm}
        onSubmit={handleSubmit}
        innerRef={formRef}
        enableReinitialize
      >
        {(!isInEditAR || isHeightRequiredType) && (
          <div className={styles.content}>
            <Typography size="small600" color="textSecondary">
              {localeStore.t('["ar-media"]["media-editor"]["product-selector"].title')}
            </Typography>
            {!isInEditAR && (
              <FormField
                data-cy="enter-sku"
                appearance="primaryV2"
                type="select"
                name="sku"
                label={localeStore.t(
                  '["ar-media"]["media-editor"]["product-selector"].fields.sku.label',
                )}
                labelMessage={localeStore.t(
                  '["ar-media"]["media-editor"]["product-selector"].fields.sku["label-message"]',
                )}
                placeholder={localeStore.t(
                  '["ar-media"]["media-editor"]["product-selector"].fields.sku.placeholder',
                )}
                createNewText={localeStore.t(
                  '["ar-media"]["media-editor"]["product-selector"].fields.sku["create-new-text"]',
                )}
                initiallySelectedOptions={{
                  ...product,
                  label: product?.title,
                  value: product?._id,
                }}
                options={useSelectAutoComplete.options}
                onChange={handleProductSelect}
                onSearch={useSelectAutoComplete.onSearch}
                onCreateNew={handleCreateProduct}
                loading={productLoading || useSelectAutoComplete.loading}
                canCreate={unique}
                isTextEditable
                required
              />
            )}

            {isHeightRequiredType && (
              <div className={styles.measure}>
                <FormField
                  appearance="primaryV2"
                  type="number"
                  name="height"
                  label={localeStore.t(
                    '["ar-media"]["media-editor"]["product-selector"].fields.height.label',
                  )}
                  placeholder={localeStore.t(
                    '["ar-media"]["media-editor"]["product-selector"].fields.height.placeholder',
                  )}
                  onChange={(_height) => {
                    if (Number(_height) <= 0) return;
                    const height = measurementToPixel(
                      Number(_height),
                      formRef.current?.values.unit,
                      productType.name,
                    );

                    const aspectRatio =
                      mainImageComponent.transform.size.ratioAO ||
                      initialMainImageRectData.aspectRatio;
                    const width = aspectRatio * height;

                    const newSize = new Vec2(width, height);

                    mainImageComponent.transform.calcDimensions({
                      position: canvasSize.getHalf(),
                      size: newSize,
                    });
                    // TODO: an inquiry into the root cause of the issue is necessary.
                    // Webgl2New.Texture.resizeTexture(
                    //   mainImageComponent.state.texture,
                    //   mainImageComponent.state.texture,
                    //   newSize,
                    // );
                    renderer.selectionComponent.copyComponentTransform(renderer.selectedComponent);
                  }}
                  required
                />
                <FormField
                  appearance="primaryV2"
                  inputWrapperClassName={styles.unitWrapper}
                  type="select"
                  options={measureOptions}
                  name="unit"
                  label={localeStore.t(
                    '["ar-media"]["media-editor"]["product-selector"].fields.unit.label',
                  )}
                  placeholder={localeStore.t(
                    '["ar-media"]["media-editor"]["product-selector"].fields.unit.placeholder',
                  )}
                  onChange={(option) => {
                    if (option.value === formRef.current?.values.unit) return;

                    newMeasureRef.current = option.value;

                    const height = measurementToPixel(
                      Number(formRef.current?.values.height),
                      option.value,
                      productType.name,
                    );

                    const aspectRatio =
                      mainImageComponent.transform.size.ratioAO ||
                      initialMainImageRectData.aspectRatio;
                    const width = aspectRatio * height;

                    const newSize = new Vec2(width, height);

                    mainImageComponent.transform.calcDimensions({
                      position: canvasSize.getHalf(),
                      size: newSize,
                    });
                    Webgl2New.Texture.resizeTexture(
                      mainImageComponent.state.texture,
                      mainImageComponent.state.texture,
                      newSize,
                    );
                    renderer.selectionComponent.copyComponentTransform(renderer.selectedComponent);
                  }}
                  disableClearing
                  disableSingleOptionUncheck
                  required
                />
              </div>
            )}
          </div>
        )}

        <div className={styles.buttons}>
          <Button appearance="tertiaryOutlined" onClick={onCancel}>
            {localeStore.t('["ar-media"]["media-editor"]["product-selector"].buttons.back')}
          </Button>
          <Button type="submit" data-cy="create-blink">
            {localeStore.t('["ar-media"]["media-editor"]["product-selector"].buttons.save')}
          </Button>
        </div>
      </Form>
    );
  },
);
