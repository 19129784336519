import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { SelectGroup, SelectGroupOption, SvgIcon, clsx } from "@gemlightbox/core-kit";

import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { SupportedLocalesModel, SupportedUppercaseLocalesModel } from "src/models";
import { useStores } from "src/hooks";
import { languageOptions } from "./language-select.constants";

import styles from "./language-select.module.css";
import { pushUserAttributeDataLayerEvent } from "src/utils";

export type LanguageSelectProps = {
  className?: string;
  inputWrapperClassName?: string;
  onChangeLocale?: (value: SupportedUppercaseLocalesModel) => void;
};

export const LanguageSelect: React.FC<LanguageSelectProps> = observer(
  ({ className, inputWrapperClassName, onChangeLocale }: LanguageSelectProps) => {
    const { localeStore, appCallbackStore } = useStores();

    // const locale: SupportedUppercaseLocalesModel =
    //   (localeStore.currentLocaleCode?.toUpperCase() as SupportedUppercaseLocalesModel) ?? "EN";

    const [locale, setLocale] = useState(
      (localeStore.currentLocaleCode?.toUpperCase() as SupportedUppercaseLocalesModel) ?? "EN",
    );

    const handleChangeLocale = (value: SupportedUppercaseLocalesModel) => {
      setLocale(value);

      if (onChangeLocale) {
        onChangeLocale(value);
      } else {
        const lowerCaseValue = value.toLowerCase() as SupportedLocalesModel;
        localeStore.fetchLocale(lowerCaseValue);
        appCallbackStore.setLocalizedLanguage(lowerCaseValue);
        pushUserAttributeDataLayerEvent({
          lang: lowerCaseValue,
        });
      }
    };

    const getLanguageLabelLocalized = (value: SupportedUppercaseLocalesModel) => {
      return localeStore.t(
        `settings.profile["language-block"]["language-labels"]["${value.toLowerCase()}"]` as LocaleCodeTypes,
      );
    };

    const renderFlagIcon = () => {
      const code = languageOptions.find((option) => option.value === locale)?.code || "";
      return (
        <SvgIcon icon={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`} />
      );
    };

    const localeLabel = useMemo(() => {
      const language =
        languageOptions.find((option) => option.value === locale) || languageOptions[0];
      return `${getLanguageLabelLocalized(language.value)}`;
    }, [locale, localeStore.currentLocale]);

    return (
      <SelectGroup
        className={className}
        inputWrapperClassName={clsx(styles.languageSelectWrapper, inputWrapperClassName)}
        searchValue={localeLabel}
        value={locale}
        onChange={handleChangeLocale}
        renderPrefix={renderFlagIcon}
        loading={localeStore.loading}
        disableClear
        disableError
        disableUncheck
        disableSearch
      >
        {languageOptions.map((option) => {
          const label = getLanguageLabelLocalized(option.value);
          return (
            <SelectGroupOption
              key={option.value}
              contentContainerClassName={styles.languageOption}
              value={option.value}
            >
              <SvgIcon
                icon={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${option.code}.svg`}
              />
              {`${label}`}
            </SelectGroupOption>
          );
        })}
      </SelectGroup>
    );
  },
);

export default LanguageSelect;
