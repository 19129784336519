import { LanguageOptionType } from "./language-select.types";

import { ReactComponent as DEFlagIcon } from "src/external-ts/assets/images/flags/de.svg";
import { ReactComponent as ESFlagIcon } from "src/external-ts/assets/images/flags/es.svg";
import { ReactComponent as HEFlagIcon } from "src/external-ts/assets/images/flags/he.svg";
import { ReactComponent as HIFlagIcon } from "src/external-ts/assets/images/flags/hi.svg";
import { ReactComponent as JAFlagIcon } from "src/external-ts/assets/images/flags/ja.svg";
import { ReactComponent as KOFlagIcon } from "src/external-ts/assets/images/flags/ko.svg";
import { ReactComponent as UKFlagIcon } from "src/external-ts/assets/images/flags/uk.svg";
import { ReactComponent as ZHFlagIcon } from "src/external-ts/assets/images/flags/zh.svg";
import { ReactComponent as TWFlagIcon } from "src/external-ts/assets/images/flags/tw.svg";

export const languageOptions: LanguageOptionType[] = [
  {
    label: "English",
    value: "EN",
    icon: UKFlagIcon,
    code: "GB",
  },
  {
    label: "Korean",
    value: "KO",
    icon: KOFlagIcon,
    code: "KR",
  },
  {
    label: "Chinese (simplified)",
    value: "ZH",
    icon: ZHFlagIcon,
    code: "CN",
  },
  {
    label: "Chinese (traditional)",
    value: "ZH_TW",
    icon: TWFlagIcon,
    code: "TW",
  },
  {
    label: "Spanish",
    value: "ES",
    icon: ESFlagIcon,
    code: "ES",
  },
  {
    label: "German",
    value: "DE",
    icon: DEFlagIcon,
    code: "DE",
  },
  {
    label: "Hindi",
    value: "HI",
    icon: HIFlagIcon,
    code: "IN",
  },
  {
    label: "Japanese",
    value: "JA",
    icon: JAFlagIcon,
    code: "JP",
  },
  {
    label: "Hebrew",
    value: "HE",
    icon: HEFlagIcon,
    code: "IL",
  },
];
