/* eslint-disable no-undef */
(function () {
  const systemInfo = {
    type: "Web",
  };
  const platform = navigator.platform.toLowerCase();
  const userAgent = navigator.userAgent.toLowerCase();
  const native = window.webkit?.messageHandlers?.PicupMediaNativeAPI || window?.chrome?.webview;
  const type = native ? "App" : "Web";
  const isApp = !!native;
  const os = platform.indexOf("mac") !== -1 || userAgent.indexOf("mac") !== -1 ? "Mac" : "Win";

  systemInfo.native = native;
  systemInfo.type = type;
  systemInfo.os = os;
  systemInfo.isApp = isApp;
  systemInfo.getEclipseExposureTime = (cameraVersion = "") => {
    const devNames = ["GemCam V2".toLowerCase(), "GemCam V3".toLowerCase()];
    if (os === "Win" && isApp) {
      return devNames.some((item) => cameraVersion.toLowerCase().includes(item)) ? -4 : -8;
    } else {
      return devNames.some((item) => cameraVersion.toLowerCase().includes(item)) ? 157 : 12;
    }
  };

  window.$platform = {
    ...systemInfo,
  };
})();
