import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { observer } from "mobx-react-lite";
import { Heading, Button, SvgIcon, useDidMount, useDidUnmount } from "@gemlightbox/core-kit";

import WSConnection from "src/common/socket";
import { AttributesIdMapKeys, AttributesIdMap } from "src/store";
import { useStores } from "src/hooks";
import { ColumnsList } from "./columns-list";
import { AttributesPanel } from "./attributes-panel";

import styles from "./products-import.module.css";
import { ReactComponent as CrossSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";

export const ProductsImport = observer(() => {
  const navigate = useNavigate();
  const { productsImportStore, modalsStore, localeStore, userStore } = useStores();

  const [productsConnection] = useState(() => new WSConnection("/product", userStore.token));

  useDidMount(() => {
    if (!productsImportStore.ready) {
      navigate(-1);
    }
  });

  useDidUnmount(() => {
    productsImportStore.resetState();
    productsConnection.destroy();

    modalsStore.close("FinishImportModal");
  });

  const handleDragEnd = ({ source, destination, draggableId }: DropResult) => {
    const group = AttributesIdMap[source.droppableId as AttributesIdMapKeys];

    if (!destination) return;

    const destinationColumnIndex = productsImportStore.columns.findIndex(
      ({ id }) => id === destination.droppableId,
    );

    if (destinationColumnIndex !== -1 && group) {
      const attributesGroup = productsImportStore.attributes[group];
      const attributeIndex = attributesGroup.findIndex(({ id }) => id === draggableId);

      if (attributeIndex !== -1) {
        productsImportStore.assignAttribute(group, attributeIndex, destinationColumnIndex);
      }
    } else {
      console.error(`Destination element with id: ${destination.droppableId} is not droppable`);
    }
  };

  const handleExit = () => navigate(-1);

  return (
    <main className={styles.container}>
      <Button className={styles.exit} appearance="secondaryGhost" onClick={handleExit}>
        <SvgIcon icon={CrossSVG} />
      </Button>

      <Heading tag="h2" color="textSecondary">
        {localeStore.t('["products-import"].title')}
      </Heading>

      <div className={styles.content}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <AttributesPanel connection={productsConnection} />
          <ColumnsList />
        </DragDropContext>
      </div>
    </main>
  );
});
