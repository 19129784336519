import { makeAutoObservable, observable, toJS } from "mobx";
import { uniqId } from "@gemlightbox/core-kit";

import { AttributeModel } from "src/models";
import {
  AttributesType,
  ColumnType,
  ColumnAttributeType,
  AttributesIdMapKeys,
} from "./products-import.store.types";

export class ProductsImportStore {
  private readonly _initialAttributes: AttributesType = {
    mandatory: [],
    required: [],
    optional: [],
  };
  private _attributes: AttributesType = this._initialAttributes;
  private _assignedAttributes: ColumnAttributeType[] = [];
  private _columns: ColumnType[] = [];
  private _ready = false;

  public get attributes() {
    return this._attributes;
  }

  public get assignedAttributes() {
    return this._assignedAttributes;
  }

  public get columns() {
    return this._columns;
  }

  public get ready() {
    return this._ready;
  }

  constructor() {
    makeAutoObservable(this);
  }

  /* UI State ↓ */
  public setupState(attributes: AttributeModel[], columns: ColumnType[]) {
    this._setupAttributes(attributes);
    this._setupColumns(columns);

    this._ready = true;
  }

  public resetState() {
    this._attributes = this._initialAttributes;
    this._columns = [];
    this._ready = false;
  }

  public updateAttributes(name: string, attributes: AttributeModel[]) {
    const newAttribute = attributes.find((attribute) => attribute.name === name);
    if (!newAttribute) return;

    const required = newAttribute.required;
    const parsedAttribute = this._parseAttribute(newAttribute);

    if (required) {
      this.attributes.required.push(parsedAttribute);
    } else {
      this.attributes.optional.push(parsedAttribute);
    }
  }

  public assignAttribute(
    groupId: AttributesIdMapKeys,
    attributeIndex: number,
    columnIndex: number,
  ) {
    const attributesGroup = this._attributes[groupId];
    const attribute = attributesGroup[attributeIndex];
    const column = this._columns[columnIndex];

    if (column.name && column.nameID && column.namesGroupID) {
      return console.error(`Destination column is already assigned: ${column.displayName}`);
    }

    column.displayName = attribute.displayName;
    column.name = attribute.name;
    column.nameID = attribute.id;
    column.namesGroupID = groupId;

    attributesGroup.splice(attributeIndex, 1);

    this._assignedAttributes.push(attribute);
  }

  public unassignAttribute(
    groupId: AttributesIdMapKeys,
    attributeIndex: number,
    columnIndex: number,
  ) {
    const attributesGroup = this._attributes[groupId];
    const attribute = this._assignedAttributes[attributeIndex];
    const column = this._columns[columnIndex];

    column.displayName = "";
    column.name = "";
    column.nameID = "";
    column.namesGroupID = "";

    attributesGroup.push(attribute);

    this._assignedAttributes.splice(attributeIndex, 1);
  }
  /* UI State ↑ */

  /* Helpers ↓ */
  private _setupAttributes(attributes: AttributeModel[]) {
    // passed attributes are of observable type
    const attributesCopy = toJS(attributes);

    const mandatory = this._getMandatoryAttributes(attributesCopy);
    const required = this._getRequiredAttributes(attributesCopy);
    const optional = this._getOptionalAttributes(attributesCopy);

    this._attributes.mandatory = observable(this._parseAttributes(mandatory));
    this._attributes.required = observable(this._parseAttributes(required));
    this._attributes.optional = observable(this._parseAttributes(optional));
  }

  private _setupColumns(columns: ColumnType[]) {
    this._columns = observable(columns);
  }

  private _parseAttributes(attributes: AttributeModel[]): ColumnAttributeType[] {
    return attributes.map(this._parseAttribute);
  }

  private _parseAttribute({ type, name, kind, displayName }: AttributeModel): ColumnAttributeType {
    return {
      id: uniqId(),
      type,
      name,
      kind,
      displayName: displayName || name,
    };
  }

  private _getMandatoryAttributes(attributes: AttributeModel[]): AttributeModel[] {
    return attributes.filter(({ name }) => name === "title");
  }

  private _getRequiredAttributes(attributes: AttributeModel[]): AttributeModel[] {
    return attributes.filter(({ name, required }) => required && name !== "title");
  }

  private _getOptionalAttributes(attributes: AttributeModel[]): AttributeModel[] {
    return attributes.filter(({ required }) => !required);
  }
  /* Helpers ↑*/
}
