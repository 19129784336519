import { clsx, Loader } from "@gemlightbox/core-kit";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { activateLifetimePlan } from "src/api/subscriptions";

import { LanguageSelect, Logo } from "src/external-ts/components";
import { useStores } from "src/hooks";

import { ReactComponent as CheckCircleIcon } from "../../assets/images/check-circle.svg";
import { ReactComponent as CheckDisabledIcon } from "../../assets/images/check-disabled.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/check.svg";
import { ReactComponent as KeyIcon } from "../../assets/images/key.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/users.svg";
import styles from "./lifetime-activate.page.module.scss";

enum ErrorCode {
  ACTIVATE_CODE_INVALID = "subscription:activate_code_invalid",
  ACTIVATE_CODE_EXPIRED = "subscription:activate_code_expired",
  ACTIVATE_FAILED = "subscription:activate_failed",
}

export const LifetimeActivatePage: React.FC = observer(() => {
  const { subscriptionsStore, userStore, localeStore } = useStores();
  const isProPlan =
    subscriptionsStore.isProMonthlySubscription || subscriptionsStore.isProYearlySubscription;

  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState<boolean>();
  const [errMsg, setErrMsg] = useState<string>();
  const [showContactSupport, setShowContactSupport] = useState(false);

  const handleSubmit = async (form: FormData) => {
    setSubmitting(true);
    try {
      const fetchResult = await activateLifetimePlan.getRequest({ data: form }).fetch();
      if (fetchResult.status === "success") {
        setSubmitSuccess(true);
        if (errMsg) setErrMsg("");
      } else {
        setSubmitSuccess(false);
        if (fetchResult.status !== "error") {
          setErrMsg(
            fetchResult.error.formattedMessage ||
              localeStore.t(
                'activate["lifetime-plan"]["network-error"]',
                "Network error, please retry or check your network connection.",
              ),
          );
        } else {
          setErrMsg(
            fetchResult.error.originalError.message ||
              localeStore.t(
                'activate["lifetime-plan"]["something-wrong"]',
                "Something went wrong.",
              ),
          );
          if (fetchResult.error.originalError.code !== ErrorCode.ACTIVATE_CODE_INVALID) {
            setShowContactSupport(true);
          }
        }
      }
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
    }
  };

  const renderHeader = () => {
    return (
      <div className={styles.logoContainer}>
        <Logo />
      </div>
    );
  };
  const renderFooter = () => {
    return (
      <div className={styles.footer}>
        <LanguageSelect
          className={styles.langSelect}
          inputWrapperClassName={styles.langSelectInputWrapper}
        />
        <a href="https://picupmedia.com/contact-us#support" style={{ fontWeight: 500 }}>
          {localeStore.t('activate["lifetime-plan"].help', "Help")}
        </a>
      </div>
    );
  };

  if (userStore.loadingMe || subscriptionsStore.isLoadingSubscription) {
    return <Loader type="goo-loader" position="absolute" />;
  }

  if (isProPlan) {
    return (
      <div className={styles.container}>
        {renderHeader()}
        <div className={styles.accountProblem}>
          <UsersIcon />
          <div className={styles.content}>
            {localeStore.t(
              'activate["lifetime-plan"].error["pro-plan"]',
              "Your account is already pro subscription and cannot be activated. Please contact customer service.",
            )}
          </div>
          <a href="https://picupmedia.com/contact-us#support">
            <button className={styles.button}>
              {localeStore.t('activate["lifetime-plan"]["contact-us"]', "Contact Us")}
            </button>
          </a>
        </div>
        {renderFooter()}
      </div>
    );
  }

  if (userStore.isSubaccount) {
    return (
      <div className={styles.container}>
        {renderHeader()}
        <div className={styles.accountProblem}>
          <UsersIcon />
          <div className={styles.content}>
            {localeStore.t(
              'activate["lifetime-plan"].error.subaccount',
              "Your account is part of a master account and cannot be activated. Please contact customer service.",
            )}
          </div>
          <a href="https://picupmedia.com/contact-us#support">
            <button className={styles.button}>
              {localeStore.t('activate["lifetime-plan"]["contact-us"]', "Contact Us")}
            </button>
          </a>
        </div>
        {renderFooter()}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {renderHeader()}
      <div
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: localeStore.t(
            'activate["lifetime-plan"].title',
            "Activate your <b>Lifetime</b> GemHub subscription today. Get ready to unlock all the amazing features and benefits with these simple steps:",
          ),
        }}
      />
      <div className={clsx(styles.stepContainer, submitSuccess && styles.active)}>
        <div className={styles.stepIndicator}>
          <div className={styles.stepIcon}>
            <KeyIcon />
          </div>
          <div className={styles.stepLine} />
        </div>
        <div className={styles.stepBody}>
          <div className={styles.stepTitle}>
            {localeStore.t(
              'activate["lifetime-plan"]["step1-title"]',
              "Activate your Lifetime membership",
            )}
          </div>
          <form
            className={styles.stepContent}
            style={{ marginBottom: 44 }}
            onSubmit={(e) => {
              e.preventDefault();
              const form = new FormData(e.currentTarget);
              // validate form data here
              handleSubmit(form);
            }}
          >
            <div>
              {localeStore.t(
                'activate["lifetime-plan"]["step2-title"]',
                "Enter your unique activation code.",
              )}
            </div>
            <div className={clsx(styles.inputWrapper, submitSuccess === false && styles.error)}>
              <input
                required
                disabled={submitSuccess}
                name="code"
                className={styles.input}
                placeholder="0000-0000-0000-0000"
              />
              {submitSuccess && <CheckCircleIcon />}
            </div>
            {errMsg && (
              <div className={styles.errMsg}>
                {errMsg}&nbsp;
                {showContactSupport && (
                  <a href="https://picupmedia.com/contact-us#support">
                    {localeStore.t(
                      'activate["lifetime-plan"]["contact-support"]',
                      "contact support",
                    )}
                  </a>
                )}
              </div>
            )}
            {!submitSuccess && (
              <button type="submit" disabled={submitting} className={styles.button}>
                {localeStore.t('activate["lifetime-plan"].activate', "Activate")}
                {submitting && (
                  <span
                    className="lds-dual-ring"
                    style={{ verticalAlign: "middle", marginLeft: 8 }}
                  />
                )}
              </button>
            )}
          </form>
        </div>
      </div>
      <div className={styles.stepContainer}>
        <div className={styles.stepIndicator}>
          <div className={styles.stepIcon}>
            {submitSuccess ? <CheckIcon /> : <CheckDisabledIcon />}
          </div>
        </div>
        <div className={styles.stepBody}>
          <div className={styles.stepTitle} style={{ marginBottom: 16 }}>
            {localeStore.t(
              'activate["lifetime-plan"].enjoy',
              "Enjoy lifetime access to all photography features",
            )}
          </div>
          <div className={styles.stepContent}>
            {submitSuccess && (
              <a href="/">
                <button className={styles.button}>
                  {localeStore.t('activate["lifetime-plan"].goto', "Go to GemHub")}
                </button>
              </a>
            )}
          </div>
        </div>
      </div>
      {renderFooter()}
    </div>
  );
});

export default LifetimeActivatePage;
