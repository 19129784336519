import { PaginationList } from "@gemlightbox/core-kit";

import { dashboardApiMiddleware } from "src/api";
import { MediaSortTypes } from "src/containers/media/media.constants";
import {
  CategoriesArrayModel,
  LogoWatermarkModel,
  MediaCollectionModel,
  MediaMetadataModel,
  MediaModel,
  MediaShortModel,
  MediaType,
} from "src/models";
import {
  PostCreateMediaCollectionData,
  PostLinkPasswordRequestData,
  PostBlinkMediaData,
  PostUploadMediaData,
  PutUpdateMediaData,
  PutUpdateMediasData,
} from "./media.interface";

export type GetMediasFilters = {
  sku: string;
  page: number;
  limit: number;
  user_id: string | number;
  sort: MediaSortTypes | "all";
  type: MediaType | "all";
};

export const getMedias = dashboardApiMiddleware<PaginationList<MediaModel>>()({
  method: "get",
  endpoint: "/api/content/list",
  auxiliaryKeys: {
    isDisableCache: true,
  },
});

export const getMedia = dashboardApiMiddleware<MediaShortModel>()({
  method: "get",
  endpoint: "/api/content/:mediaId",
});

export const postBlinkMedia = dashboardApiMiddleware<MediaModel, PostBlinkMediaData>()({
  method: "post",
  endpoint: "/api/content/blink",
  isFormData: true,
});

export const putBlinkMedia = dashboardApiMiddleware<MediaModel, PostBlinkMediaData>()({
  method: "put",
  endpoint: "/api/content/blink/:mediaId",
  isFormData: true,
});

export const deleteUnAssignMedia = dashboardApiMiddleware()({
  method: "delete",
  endpoint: "/api/content/unassign/:media_id",
});

export const deleteMedia = dashboardApiMiddleware<PaginationList<MediaModel>>()({
  method: "delete",
  endpoint: "/api/content",
});

export const putUpdateMedia = dashboardApiMiddleware<
  PaginationList<MediaModel>,
  PutUpdateMediaData | FormData
>()({
  method: "put",
  endpoint: "/api/content/:id",
  isFormData: true,
});

export const putUpdateMedias = dashboardApiMiddleware<
  PaginationList<MediaModel>,
  PutUpdateMediasData | FormData
>()({
  method: "put",
  endpoint: "/api/content",
  isFormData: true,
});

export const postUploadMedia = dashboardApiMiddleware<
  PaginationList<MediaModel>,
  PostUploadMediaData | FormData
>()({
  method: "post",
  endpoint: "/api/content/store/",
  isFormData: true,
});

export const postUploadFiles = dashboardApiMiddleware<
  MediaMetadataModel[],
  PostUploadMediaData | FormData
>()({
  method: "post",
  endpoint: "/api/content/temp",
  isFormData: true,
});

export const postCreateMediaCollection = dashboardApiMiddleware<
  MediaCollectionModel,
  PostCreateMediaCollectionData
>()({
  method: "post",
  endpoint: "/api/content/collection",
});

export const postLinkPassword = dashboardApiMiddleware<undefined, PostLinkPasswordRequestData>()({
  method: "post",
  endpoint: "/api/link/password/:id",
});

export const getLogoWatermark = dashboardApiMiddleware<Array<LogoWatermarkModel>>()({
  method: "get",
  endpoint: "/api/watermark",
});

export const postLogoWatermark = dashboardApiMiddleware<
  Array<LogoWatermarkModel>,
  Array<LogoWatermarkModel>
>()({
  method: "post",
  endpoint: "/api/watermark",
});

export const deleteLogoWatermark = dashboardApiMiddleware<Array<LogoWatermarkModel>>()({
  method: "delete",
  endpoint: "/api/watermark",
});

export const getGroups = dashboardApiMiddleware<CategoriesArrayModel>()({
  method: "get",
  endpoint: "/api/content/groups",
});
