import React from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  clsx,
  Heading,
  useDidMount,
  useWindowSize,
  WIDTH_BREAKPOINTS,
} from "@gemlightbox/core-kit";

import { usePageViewEvent, useStores } from "src/hooks";
import { HeaderProfileButton } from "src/external-ts/components/business/header-profile-button";
import { ShareSettings } from "./share-settings";
import { Billing } from "./billing";
import { Profile } from "./profile";
import { Subscriptions } from "./subscriptions";
import { ArSettings } from "./ar-settings";
import { UserStorageInfo } from "./user-storage-info";
import { SettingsNav } from "./settings-nav";
import { Integrations } from "./integrations";
import { Subaccounts } from "./subaccounts";
import { DNSSettings } from "./dns-settings";
import ShopifySelectStoreModal from "./integrations/modals/shopify-select-store-modal/shopify-select-store-modal";
import { SettingTabNames } from "./settings.constants";
import { getSettingsTab } from "./settings.utils";

import "./index.scss";

export const Settings = observer(() => {
  usePageViewEvent();

  const location = useLocation();

  const { windowWidth } = useWindowSize();

  const { localeStore, userStore, subscriptionsStore } = useStores();

  useDidMount(() => {
    userStore.loadUserProfile();
    userStore.loadStorageUsage();
    subscriptionsStore.loadSubscription();
  });

  const isShareSettingsTabOpened =
    location.pathname === getSettingsTab(SettingTabNames.gallery)?.item.path;

  const isIntegrations = location.pathname.includes(
    getSettingsTab(SettingTabNames.integrations)?.item.path as string,
  );

  return (
    <main className="settings">
      {windowWidth > WIDTH_BREAKPOINTS.mobileL ? (
        <div className="settings-main">
          <div className="settings-header">
            <Heading tag="h3" color="textSecondary">
              {localeStore.t("settings.title")}
            </Heading>
            <HeaderProfileButton />
          </div>
          <SettingsNav />
        </div>
      ) : (
        <>
          <Heading tag="h3" color="textSecondary">
            {localeStore.t('settings["title-mobile"]')}
          </Heading>
          <SettingsNav />
        </>
      )}

      <div
        className={clsx("settings-tab-content secondary-scrollbar", {
          "share-settings-tab-content": isShareSettingsTabOpened,
          "integrations-settings-tab-content": isIntegrations,
        })}
      >
        <Routes>
          <Route
            path={getSettingsTab(SettingTabNames.account)?.item.relativePath}
            element={
              <>
                <Profile />
                <UserStorageInfo />
              </>
            }
          />
          <Route
            path={getSettingsTab(SettingTabNames.billing)?.item.relativePath}
            element={<Billing />}
          />
          <Route
            path={getSettingsTab(SettingTabNames.subaccounts)?.item.relativePath}
            element={<Subaccounts />}
          />
          <Route
            path={getSettingsTab(SettingTabNames.gallery)?.item.relativePath}
            element={<ShareSettings />}
          />
          <Route
            path={getSettingsTab(SettingTabNames.arsettings)?.item.relativePath}
            element={<ArSettings />}
          />
          <Route
            path={getSettingsTab(SettingTabNames.subscriptions)?.item.relativePath}
            element={<Subscriptions />}
          />
          <Route
            path={getSettingsTab(SettingTabNames.integrations)?.item.relativePath}
            element={<Integrations />}
          />
          <Route
            path={getSettingsTab(SettingTabNames.integrations)?.item.shopify?.settings.relativePath}
            element={
              <Integrations
                {...getSettingsTab(SettingTabNames.integrations)?.item.shopify?.settings}
              />
            }
          />
          <Route
            path={getSettingsTab(SettingTabNames.dns)?.item.relativePath}
            element={<DNSSettings />}
          />
          <Route path="*" element={<Navigate to={"/settings/account-info"} />} />
        </Routes>
      </div>

      <ShopifySelectStoreModal />
    </main>
  );
});
