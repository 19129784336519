import React from "react";
import { observer } from "mobx-react-lite";
import { useMediaBreakpoints } from "@gemlightbox/core-kit";

import { DesktopLayout } from "./desktop-layout";
import { TabletLayout } from "./tablet-layout";
import { MobileLayout } from "./mobile-layout";

export const Navbar: React.FC = observer(() => {
  const { isMobileMedia, isTabletMedia, isDesktopMedia } = useMediaBreakpoints(undefined, {
    mobileBreakpoint: 767,
  });

  const isTablet = isTabletMedia && !isMobileMedia;

  return (
    <>
      {isDesktopMedia && <DesktopLayout />}
      {isTablet && <TabletLayout />}
      {isMobileMedia && <MobileLayout />}
    </>
  );
});
