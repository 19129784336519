import React from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { useStores } from "src/hooks";
import { cameraStore } from "../camera.store";
import { Button, SvgIcon, UseBooleanReturnType } from "@gemlightbox/core-kit";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import { ReactComponent as CloseSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";
import { ReactComponent as BlockBluetoothSVG } from "src/external-ts/assets/images/camera/verify-block-bluetooth.svg";
import { ReactComponent as BlockedBluetoothSVG } from "src/external-ts/assets/images/camera/verify-blocked-bluetooth.svg";

import styles from "./bluetooth-permission-modal.module.css";
import { MEDIA_PAGE } from "src/constants";

const BootstrapDialog = styled(Dialog)(() => ({
  "& .MuiPaper-root": {
    background: "linear-gradient(180deg, #9BE7EB 0%, #FFF 46.5%)",
    borderRadius: "16px",
  },
  ".MuiDialog-paper": {
    maxWidth: "324px",
  },
}));

type BluetoothPermModalProps = {
  bluetoothPermBool: UseBooleanReturnType;
};

export const BluetoothPermModal: React.FC<BluetoothPermModalProps> = observer(
  ({ bluetoothPermBool }) => {
    const navigate = useNavigate();
    const { appCallbackStore, localeStore, modalsStore } = useStores();
    const { bluetoothStatus } = cameraStore;

    const handleOpenBluetooth = () => {
      if (bluetoothStatus < 4) {
        // 跳转蓝牙授权
        appCallbackStore.openSystemBluetoothAuthSetting();
      } else {
        // 打开蓝牙开关
        appCallbackStore.openSystemBluetoothSetting();
      }
      handleCloseBluetooth();
    };

    const handleCloseBluetooth = () => {
      bluetoothPermBool.setFalsy();
      modalsStore.open("DeviceListModal", {
        bluetoothPermBool,
        onClose: () => navigate(MEDIA_PAGE.path),
      });
    };

    return (
      <BootstrapDialog open={bluetoothPermBool.value} maxWidth="xs">
        <DialogTitle className={styles.bluetoothDialogHeader}>
          <SvgIcon
            className={styles.closeButton}
            icon={CloseSVG}
            size={24}
            onClick={handleCloseBluetooth}
          />
        </DialogTitle>
        <DialogContent>
          <div className={styles.bluetoothDialogContent}>
            <SvgIcon
              size={56}
              icon={bluetoothStatus < 4 ? BlockBluetoothSVG : BlockedBluetoothSVG}
            />
            <div className={styles.bluetoothDialogDesc}>
              {bluetoothStatus < 4
                ? localeStore.t('camera["bluetooth-permission-tips"]')
                : localeStore.t('camera["bluetooth-feature-tips"]')}
            </div>
            <Button className={styles.bluetoothDialogComfirm} onClick={handleOpenBluetooth}>
              {localeStore.t('camera["confirm-text"]')}
            </Button>
          </div>
        </DialogContent>
      </BootstrapDialog>
    );
  },
);
